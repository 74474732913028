import type { User } from "./user";

export enum MeetingType {
  oneOnOne = "1:1",
  feedback = "Feedback",
  performance_review = "Performance Review",
  onboarding = "Onboarding",
  problem_solving = "Problem Solving",
  project_kickoff = "Project Kickoff",
}

export enum MeetingState {
  cancelled = "Canceled",
  pending = "Pending",
  done = "Done",
}

export enum MeetingFeeling {
  bad = "Bad",
  neutral = "Neutral",
  good = "Good",
}

export interface Meeting {
  id: number;
  meeting_type: MeetingType;
  meeting_date: Date;
  relative_time: string;
  target: User;
  location: string;
  participants: User[];
  state: MeetingState;
}
